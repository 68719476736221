import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { graphql } from 'gatsby';
import { withStyles, Container, Typography } from '@material-ui/core';
import { get, map, isEmpty, filter, includes } from 'lodash';
import { useTranslation } from 'react-i18next';

import { contentfulContentTypes } from '../../../../constants';

import RelatedPage from './RelatedPage';

import '../../Content';

const styles = theme => ({
  root: {
    padding: theme.spacing(2, 0),
    '@media print': {
      display: 'none',
    },
  },
  title: {
    margin: theme.spacing(3, 0),
  },
  relatedRecipes: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

function RelatedRecipePages({ classes, className, pageData }) {
  const { t } = useTranslation();
  const relatedRecipePages = get(pageData, 'relatedRecipePages');
  if (isEmpty(relatedRecipePages)) {
    return null;
  }

  const hasSearchModule = !isEmpty(
    filter(get(pageData, 'content.references'), reference => {
      return includes(reference, contentfulContentTypes.SearchModule);
    }),
  );
  if (hasSearchModule) {
    return null;
  }

  return (
    <Container disableGutters className={clsx(classes.root, className)}>
      <Typography variant="h2" align="center" className={classes.title}>
        {t('RelatedRecipes.title')}
      </Typography>
      <div className={classes.relatedRecipes}>
        {map(relatedRecipePages, (relatedpage, index) => {
          return <RelatedPage key={index} data={relatedpage} />;
        })}
      </div>
    </Container>
  );
}

RelatedRecipePages.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

RelatedRecipePages.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(RelatedRecipePages);

export const query = graphql`
  fragment RelatedRecipePageFragment on ContentfulRecipePage {
    ...ContentRecipePageFragment
  }
`;
