/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { withStyles } from '@material-ui/core';

import Header from '../../../components/layout/Header';
import Footer from '../../../components/layout/Footer';
import SeoFields from '../../../components/page/SeoFields';
import HeroImage from '../../../components/page/HeroImage';
import ShortDescription from '../../../components/page/ShortDescription';
import Content from '../../../components/page/Content';
import RelatedArticlePages from '../../../components/page/RelatedArticlePages';
import RelatedRecipePages from '../../../components/page/RelatedRecipePages';
import SiteNotification from '../../../components/page/SiteNotifications';
import AnalyticsModule from '../../../components/module/AnalyticsModule';
import withSecondaryTheme from '../../../components/shared/ThemeStyle/withSecondaryTheme';
import Description from '../../../components/page/SeoDescriptoin';

import useInitI18next from '../../../components/hooks/useInitI18next';
import useInitLuxon from '../../../components/hooks/useInitLuxon';

const styles = theme => ({
  root: {
    display: 'block',
    paddingTop: 0,
  },
  shortDescription: {
    color: theme.palette.common.darkPurple,
    maxWidth: '600px',
    padding: theme.spacing(8, 3),
    textAlign: 'center',
    '&> p': {
      fontSize: theme.typography.h3.fontSize,
    },
  },
});

function CategoryPageTemplatePhilipsonSoderberg({ classes, data }) {
  const pageData = get(data, 'contentfulCategory');
  const analytics = get(pageData, 'mainTheme.analytics');

  useInitI18next();
  useInitLuxon();

  return (
    <div className={classes.root}>
      <SeoFields pageData={pageData} />
      <Header pageData={pageData} />
      <AnalyticsModule data={analytics} />
      <main>
        <SiteNotification pageData={pageData} />
        <HeroImage pageData={pageData} showTitle />
        <ShortDescription pageData={pageData} className={classes.shortDescription} />
        <Content pageData={pageData} />
        <RelatedArticlePages pageData={pageData} />
        <RelatedRecipePages pageData={pageData} />
        <Description pageData={pageData} />
      </main>
      <Footer pageData={pageData} />
    </div>
  );
}

CategoryPageTemplatePhilipsonSoderberg.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

CategoryPageTemplatePhilipsonSoderberg.defaultProps = {
  data: null,
  classes: {},
};

export default withSecondaryTheme(withStyles(styles)(CategoryPageTemplatePhilipsonSoderberg));
