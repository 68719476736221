import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import ViinimaaRelatedArticlePages from './viinimaa';

function RelatedArticlePages(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaRelatedArticlePages {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaRelatedArticlePages {...props} />;
  }
  if (isEqual(siteId, siteIds.Wennerco)) {
    return <ViinimaaRelatedArticlePages gap="40" {...props} />;
  }
  if (brandSite) {
    return <ViinimaaRelatedArticlePages {...props} />;
  }

  return null;
}

export default RelatedArticlePages;
