import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core';
import { get } from 'lodash';

import ListElementCard from '../../../shared/ListElementCard';
import RichText from '../../../shared/RichText';

const styles = () => ({
  root: {},
});

function RelatedPage({ classes, className, data }) {
  const image = get(data, 'heroImage');
  const title = get(data, 'title');
  const shortDescription = get(data, 'shortDescription');
  const categories = get(data, 'categories');
  const pageType = get(data, '__typename');
  const fullPath = get(data, 'fullPath');

  return (
    <ListElementCard
      className={clsx(classes.root, className)}
      richText={RichText}
      image={image}
      title={title}
      categories={categories}
      description={shortDescription}
      fullPath={fullPath}
      pageType={pageType}
    />
  );
}

RelatedPage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

RelatedPage.defaultProps = {
  classes: {},
  className: null,
  data: null,
};
export default withStyles(styles)(RelatedPage);
