import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { graphql } from 'gatsby';
import { withStyles, Container } from '@material-ui/core';
import { get, map, isEmpty, filter, includes } from 'lodash';

import RelatedPage from './RelatedPage';
import { contentfulContentTypes } from '../../../../constants';

import '../../Content';

const styles = theme => ({
  root: {
    padding: theme.spacing(2, 0),
    '@media print': {
      display: 'none',
    },
  },
  title: {
    margin: theme.spacing(3, 0),
  },
  relatedArticles: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: ({ gap }) => {
      if (gap) {
        return `${gap}px`;
      }
      return '0';
    },
  },
});

function RelatedArticlePages({ classes, className, pageData, ...otherProps }) {
  const relatedArticlePages = get(pageData, 'relatedArticlePages');
  if (isEmpty(relatedArticlePages)) {
    return null;
  }

  const hasSearchModule = !isEmpty(
    filter(get(pageData, 'content.references'), reference => {
      return includes(reference, contentfulContentTypes.SearchModule);
    }),
  );
  if (hasSearchModule) {
    return null;
  }

  return (
    <Container disableGutters className={clsx(classes.root, className)}>
      <div className={classes.relatedArticles}>
        {map(relatedArticlePages, (relatedpage, index) => {
          return <RelatedPage key={index} data={relatedpage} {...otherProps} />;
        })}
      </div>
    </Container>
  );
}

RelatedArticlePages.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

RelatedArticlePages.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(RelatedArticlePages);

export const query = graphql`
  fragment RelatedArticlePageFragment on ContentfulArticlePage {
    ...ContentArticlePageFragment
  }
`;
