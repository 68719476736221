import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get } from 'lodash';
import { withStyles, Container } from '@material-ui/core';
import RichText from '../../../shared/RichText';
import { contentPageMaxWidth } from '../../../../constants';

const styles = theme => ({
  root: {
    display: 'block',
  },
  seoDescription: {
    margin: theme.spacing(4, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(6, 0),
    },
  },
});

function SeoDescription({ classes, className, pageData }) {
  const seoDescription = get(pageData, 'description');

  return (
    <Container maxWidth={contentPageMaxWidth} className={clsx(classes.root, className)}>
      <RichText textData={seoDescription} className={classes.seoDescription} />
    </Container>
  );
}

SeoDescription.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

SeoDescription.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(SeoDescription);
