import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { withStyles } from '@material-ui/core';

import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import SeoFields from '../../components/page/SeoFields';
import HeroImage from '../../components/page/HeroImage';
import Title from '../../components/page/Title';
import ShortDescription from '../../components/page/ShortDescription';
import Content from '../../components/page/Content';
import RelatedArticlePages from '../../components/page/RelatedArticlePages';
import RelatedRecipePages from '../../components/page/RelatedRecipePages';
import CategoryRelatedProducts from '../../components/page/CategoryRelatedProducts';
import SiteNotification from '../../components/page/SiteNotifications';
import AnalyticsModule from '../../components/module/AnalyticsModule';
import withSecondaryTheme from '../../components/shared/ThemeStyle/withSecondaryTheme';
import Description from '../../components/page/SeoDescriptoin';
import PageBrowseTracker from '../../components/page/PageBrowseTracker';

import useInitI18next from '../../components/hooks/useInitI18next';
import useInitLuxon from '../../components/hooks/useInitLuxon';

const styles = theme => ({
  root: {
    display: 'block',
    ...theme.palette.pageTemplates.otherPageTemplates,
  },
  products: {
    padding: theme.spacing(10, 0),
  },
});

function CategoryPageTemplate({ classes, data }) {
  const pageData = get(data, 'contentfulCategory');
  const analytics = get(pageData, 'mainTheme.analytics');

  useInitI18next();
  useInitLuxon();

  return (
    <div className={classes.root}>
      <PageBrowseTracker pageData={pageData} />
      <SeoFields pageData={pageData} />
      <Header pageData={pageData} />
      <AnalyticsModule data={analytics} />
      <main>
        <SiteNotification pageData={pageData} />
        <Title pageData={pageData} />
        <ShortDescription pageData={pageData} />
        <HeroImage pageData={pageData} />
        <Content pageData={pageData} />
        <CategoryRelatedProducts pageData={pageData} />
        <RelatedArticlePages pageData={pageData} />
        <RelatedRecipePages pageData={pageData} />
        <Description pageData={pageData} />
      </main>
      <Footer pageData={pageData} />
    </div>
  );
}

CategoryPageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

CategoryPageTemplate.defaultProps = {
  data: null,
  classes: {},
};

export default withSecondaryTheme(withStyles(styles)(CategoryPageTemplate));
