import React from 'react';

import { graphql } from 'gatsby';

import CommonCategoryPageTemplate from './CategoryPageTemplate';
import AnoraProCategoryPageTemplate from './anoraPro';
import PhilipsonSoderbergCategoryPageTemplate from './philipson-soderberg';
import { siteId } from '../../sites';
import { siteIds } from '../../constants';

function CategoryPageTemplate({ ...props }) {
  if (siteId === siteIds.AnoraPro) {
    return <AnoraProCategoryPageTemplate {...props} />;
  }
  if (siteId === siteIds.PhilipsonSoderberg) {
    return <PhilipsonSoderbergCategoryPageTemplate {...props} />;
  }
  return <CommonCategoryPageTemplate {...props} />;
}
export const pageQuery = graphql`
  query CategoryPageTemplate($pageId: String!) {
    contentfulCategory(id: { eq: $pageId }) {
      id
      contentful_id
      title
      menuTitle
      shortDescription
      fullPath
      languageCode
      categoryType
      alcoholType
      internal {
        type
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }
      seoFields {
        ...SeoDataFragment
      }
      primaryChannel {
        ...PrimaryChannelFragment
      }
      searchPage {
        ...SearchPageFragment
      }
      breadcrumbs {
        ...BreadcrumbFragment
      }
      topNavigation {
        ...TopNavigationFragment
      }
      heroImage {
        ...HeroImageFragment
      }
      content {
        ...ContentFragment
      }
      relatedProducts {
        ...RelatedProductFragment
      }
      relatedArticlePages {
        ...RelatedArticlePageFragment
      }
      relatedRecipePages {
        ...RelatedRecipePageFragment
      }
      mainTheme {
        ...themeFragment
      }
      description {
        raw
        references {
          ...CategoryDescriptionFragment
        }
      }
    }
  }
`;

export default CategoryPageTemplate;
