import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, includes, isEmpty, map } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Visible from '../../../shared/Visible';
import ProductCard from '../../../shared/Products/ProductCard';
import ProductSearch from '../../../shared/Products/ProductSearch';

import '../../Content';

const styles = theme => ({
  root: {},
  title: {
    margin: theme.spacing(3, 0),
  },
  relatedProducts: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignContent: 'center',
  },
});

function CategoryRelatedProducts({ classes, className, pageData }) {
  const { t } = useTranslation();

  const primaryChannel = get(pageData, 'primaryChannel');
  const relatedProducts = get(pageData, 'relatedProducts');
  const alcoholType = get(pageData, 'alcoholType');
  const isProductCategory = includes(get(pageData, 'categoryType'), 'Product category');

  const defaultFilters = useMemo(() => (!isEmpty(alcoholType) ? { alcohol_type: alcoholType } : {}), [alcoholType]);

  if (!isProductCategory && isEmpty(relatedProducts)) {
    return null;
  }

  return (
    <Container className={clsx(classes.root, className)} disableGutters={!isProductCategory}>
      <Typography variant="h2" align="center" className={classes.title}>
        {t('RelatedProducts.title')}
      </Typography>
      <Visible visible={isProductCategory}>
        <ProductSearch channelData={primaryChannel} defaultFilters={defaultFilters} />
      </Visible>
      <Visible visible={!isProductCategory}>
        <div className={classes.relatedProducts}>
          {map(relatedProducts, (relatedProduct, index) => {
            const relatedProductId = get(relatedProduct, 'id');
            const relatedProductKey = `${relatedProductId}-${index}`;
            return <ProductCard key={relatedProductKey} productData={relatedProduct} />;
          })}
        </div>
      </Visible>
    </Container>
  );
}

CategoryRelatedProducts.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

CategoryRelatedProducts.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(CategoryRelatedProducts);

export const query = graphql`
  fragment RelatedProductFragment on MagentoProduct {
    ...ContentProductFragment
  }
`;
